<template>
  <div class="buy-miles pd-20">
    <a-page-header class="pd-0" @back="$router.go(-1)">
      <div slot="title">
        <h1>Comprar Milhas</h1>
      </div>
    </a-page-header>
    <a-form
      class="form-contracts"
      :form="buyMilesForm"
      @submit.prevent="submitMilesCard"
    >
      <a-row :gutter="[20, 20]">
        <a-col class="box box-contractor" :span="8">
          <a-row :gutter="[20, 0]">
            <a-row class="title-box mb-20" type="flex" justify="space-between">
              <a-col>
                <h3>
                  <a-icon class="mr-5" type="user" />
                  FORNECEDOR
                </h3>
              </a-col>
              <a-col>
                <a-button type="link" size="small" ghost @click="addSupplier">
                  <a-icon type="plus" /> ADICIONAR NOVO FORNECEDOR
                </a-button>
              </a-col>
            </a-row>
            <a-col class="search-suppliers" :span="24">
              <a-auto-complete
                :data-source="
                  suppliers.map(({ first_name, last_name, id }) => ({
                    value: id,
                    text: `${id} - ${first_name} ${last_name}`,
                  }))
                "
                style="width: 100%"
                placeholder="Buscar fornecedores..."
                v-model="searchTerm"
                @search="searchSupplier"
                @select="onSupplierSelected"
              >
                <a-input>
                  <a-icon slot="prefix" type="search" />
                </a-input>
              </a-auto-complete>
            </a-col>
            <supplier-details
              class="mt-20"
              :supplier="supplier"
              v-if="supplier && action === 'viewSupplier'"
            />
            <no-data
              icon="user"
              text="Fornecedor de Milhas"
              v-if="!supplier && !action"
            />
            <a-form
              v-if="action === 'addSupplier'"
              class="form-contracts"
              @submit.prevent="submitSupplier"
              :form="supplierForm"
            >
              <supplier-data class="mt-20" :supplierForm="supplierForm" />
              <a-col class="a-center mt-10" :span="24">
                <a-button
                  icon="user"
                  type="primary"
                  :loading="processingSupplier"
                  html-type="submit"
                >
                  Cadastrar fornecedor
                </a-button>
              </a-col>
            </a-form>
          </a-row>
        </a-col>
        <a-col class="box box-services" :span="16">
          <a-row :gutter="[0, 0]">
            <h2 class="title mb-10">
              <a-row type="flex" justify="space-between" align="middle">
                <a-col>
                  <a-icon class="mr-5" type="credit-card" />
                  DADOS DO CARTÃO DE MILHAS
                </a-col>
                <a-col v-show="supplier">
                  <a-radio-group
                    size="small"
                    @change="onMilesOperationSelect"
                    default-value="buy-miles"
                    button-style="solid"
                  >
                    <a-radio-button value="buy-miles" style="font-size: 11px">
                      Comprar
                    </a-radio-button>
                    <a-radio-button value="add-miles" style="font-size: 11px">
                      Adicionar
                    </a-radio-button>
                  </a-radio-group>
                </a-col>
              </a-row>
            </h2>

            <a-alert
              v-if="supplier && milesOperation == 'buy-miles'"
              type="info"
              style="margin: 20px 10px 10px; font-size: 11px; font-weight: 500"
              :message="`Cadastrando novo cartão de milhas para o Fornecedor ${supplier.first_name} ${supplier.last_name}  #${supplier.id}.`"
              banner
            />

            <buy-miles-form
              v-show="supplier"
              :buyMilesForm="buyMilesForm"
              :milesOperation="milesOperation"
            />
            <payment-method
              v-if="
                buyMilesForm.getFieldValue('value') &&
                buyMilesForm.getFieldValue('quantity')
              "
              :buyMilesForm="buyMilesForm"
            />
            <no-data
              v-show="!supplier"
              icon="credit-card"
              text="Cartão de Milhas"
            />
          </a-row>
        </a-col>
      </a-row>
      <br />
      <a-row v-show="supplier" type="flex" justify="end">
        <a-col>
          <a-button
            icon="dollar"
            size="large"
            type="primary"
            class="mb-20"
            :loading="processing"
            html-type="submit"
          >
            {{
              milesOperation === "buy-miles"
                ? "Comprar Milhas"
                : "Adicionar Milhas"
            }}
          </a-button>
        </a-col>
      </a-row>
    </a-form>
  </div>
</template>

<script>
import suppliersMixins from "@/mixins/suppliersMixins";
import SupplierDetails from "@/views/suppliers/SupplierDetails.vue";
import NoData from "@/components/general/NoData.vue";
import SupplierData from "@/components/suppliers/SupplierHandler/SupplierData.vue";
import BuyMilesForm from "@/components/miles/BuyMiles/BuyMilesForm.vue";
import PaymentMethod from "@/components/miles/BuyMiles/PaymentMethod.vue";

export default {
  components: {
    SupplierDetails,
    NoData,
    SupplierData,
    BuyMilesForm,
    PaymentMethod,
  },
  mixins: [suppliersMixins],
  beforeMount() {
    this.searchSuppliers(1);
    // this.getSupplier(70);
    // this.action = "addSupplier";
    this.$root.$on("getSupplierRes", (supplier) => {
      // this.supplierForm.setFieldsValue(supplier);
      this.supplier = supplier;
    });

    this.$root.$on("setPricePerMiles", (pricePerMiles) => {
      this.pricePerMiles = pricePerMiles;
    });

    this.$root.$on("milesCardId", (milesCardId) => {
      this.milesCardId = milesCardId;
    });
  },
  data() {
    return {
      pricePerMiles: null,
      buyMilesForm: this.$form.createForm(this),
      supplierForm: this.$form.createForm(this),
      searchTerm: "",
      supplier: null,
      milesOperation: "buy-miles",
      airlines: [],
      action: null,
      processingSupplier: false,
      milesCardId: null,
    };
  },
  methods: {
    onMilesOperationSelect(e) {
      this.milesOperation = e.target.value;
      this.buyMilesForm.resetFields();
    },
    onSupplierSelected(id) {
      this.supplier = null;
      this.action = "viewSupplier";
      this.buyMilesForm.resetFields();
      this.getSupplier(id);
    },
    addSupplier() {
      this.searchTerm = "";
      this.supplier = null;
      this.action = "addSupplier";
    },
    submitSupplier() {
      this.supplierForm.validateFields(async (err, supplier) => {
        if (!err) {
          this.processingSupplier = true;
          const body = {
            ...supplier,
            user_id: this.$store.state.userData.id,
          };
          try {
            const { data } = await this.$http.post("/supplier/create", body);
            this.$message.success(data.message);
            this.action = "viewSupplier";
            this.supplier = {
              id: data.id,
              ...supplier,
            };
          } catch (error) {
            this.$message.error(error.response.data.message);
          } finally {
            this.processingSupplier = false;
          }
        }
      });
    },
    convertToInt(stringNumber) {
      return parseInt(stringNumber.replace(/\./g, ""));
    },
    convertMoneyToNumber(stringMoney) {
      let str = `${stringMoney}`;
      return Number(str.replace(/\./g, "").substr(3).replace(",", "."));
    },
    submitMilesCard() {
      if (!this.action) {
        this.action = "addSupplier";
      } else {
        this.buyMilesForm.validateFields((err, miles) => {
          console.log("VALORES", miles);
          if (!err) {
            delete miles.miles_card_installments;

            let {
              airline_id,
              miles_program,
              miles_program_id,
              number,
              user,
              special_price,
              password,
              password_recovery,
              expires_at,
              is_vcm,
              payment,
            } = miles;

            payment.installments.shift(); // Removendo primeiro elemento fazio do array de parcelas

            payment.installments = payment.installments.map((installment) => {
              return {
                expiration_date: installment.expiration_date,
                quantity: this.convertToInt(installment.quantity),
                value: this.convertMoneyToNumber(installment.value),
              };
            });

            let body = {
              id:
                this.milesOperation === "add-miles"
                  ? this.milesCardId
                  : undefined,
              airline_id,
              miles_program,
              miles_program_id,
              number,
              user,
              special_price: this.convertMoneyToNumber(special_price),
              password,
              password_recovery,
              expires_at,
              user_id: this.$store.state.userData.id,
              status: "available",
              payment,
              transaction: {
                user_id: this.$store.state.userData.id,
                total_miles: this.convertToInt(miles.quantity),
                suggested_value: miles.suggested_value,
                contract_id: "0",
                value: this.convertMoneyToNumber(miles.value),
                special_price: this.convertMoneyToNumber(special_price),
                is_canceled: "0",
                is_vcm,
                extra_info: "",
                price_per_miles: this.pricePerMiles / 1000,
                operation: this.milesOperation === "buy-miles" ? "new" : "add",
                expires_at,
              },
            };

            if (this.action === "addSupplier") {
              body.supplier_id = this.supplier.id;
              body.transaction.supplier_id = this.supplier.id;
              this.finishMilesCard(body);
            } else {
              this.processing = true;
              body.supplier_id = this.supplier.id;
              body.transaction.supplier_id = this.supplier.id;
              this.finishMilesCard(body);
            }

            console.log(body);
          }
        });
      }
    },
    finishMilesCard(body) {
      this.$http
        .post(
          `/miles-card/${
            this.milesOperation === "buy-miles" ? "create" : "update"
          }`,
          body
        )
        .then(({ data: { message } }) => {
          this.$message.success(message);
          this.buyMilesForm.resetFields();
          this.action = null;
          this.supplier = null;
        })
        .catch(
          ({
            response: {
              data: { message },
            },
          }) => {
            this.$message.error(message);
          }
        )
        .finally(() => {
          this.processing = false;
        });
    },
  },
};
</script>

<style lang="sass" scoped>
.search-suppliers
	float: none
</style>
