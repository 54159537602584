var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-form-item',[_c('label',{staticClass:"filled"},[_vm._v(_vm._s(_vm.label))]),_vm._v(" "),_c('a-select',_vm._g(_vm._b({directives:[{name:"decorator",rawName:"v-decorator",value:([
      `${_vm.fieldKey}`,
      {
        initialValue: _vm.initialValue,
        rules: [
          {
            required: this.required,
            message: this.requiredMessage
              ? this.requiredMessage
              : 'Obrigatório',
          },
        ],
      },
    ]),expression:"[\n      `${fieldKey}`,\n      {\n        initialValue,\n        rules: [\n          {\n            required: this.required,\n            message: this.requiredMessage\n              ? this.requiredMessage\n              : 'Obrigatório',\n          },\n        ],\n      },\n    ]"}],staticClass:"travel-input",on:{"change":_vm.onChange}},'a-select',_vm.$attrs,false),_vm.$listeners),[_vm._t("default")],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }