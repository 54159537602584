<template>
  <a-form-item>
    <label class="filled">{{ label }}</label>
    <a-select
      class="travel-input"
      v-on="$listeners"
      v-bind="$attrs"
      v-decorator="[
        `${fieldKey}`,
        {
          initialValue,
          rules: [
            {
              required: this.required,
              message: this.requiredMessage
                ? this.requiredMessage
                : 'Obrigatório',
            },
          ],
        },
      ]"
      @change="onChange"
    >
      <slot />
    </a-select>
  </a-form-item>
</template>

<script>
export default {
  name: "TSelect",
  props: {
    label: {
      type: String,
    },
    fieldKey: {
      type: String,
    },
    rules: {
      type: Array,
    },
    initialValue: null,
    required: Boolean,
    requiredMessage: {
      type: String,
    },
    form: null,
  },
};
</script>
