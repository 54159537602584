<template>
  <div class="buy-miles-form">
    <a-row :gutter="[20]" v-if="milesOperation === 'add-miles'">
      <a-col v-if="buyMilesForm.getFieldValue(`milescard_id`)" :span="24">
        <a-alert
          type="info"
          class="mb-30"
          style="font-size: 11px; font-weight: 500"
          :message="`Adicionando milhas para o cartão #${buyMilesForm.getFieldValue(
            `milescard_id`
          )}.`"
          banner
        />
      </a-col>
      <a-col :span="8">
        <t-select
          :form="buyMilesForm"
          fieldKey="milescard_id"
          required
          label="Cartões de milhas"
          placeholder="Cartões de milhas"
          @select="onMileCardSelected"
        >
          <a-select-option
            v-for="(milesCard, index) of milesCards"
            :key="index"
            :value="milesCard.id"
          >
            {{ `ID ${milesCard.id} ${milesCard.miles_program}` }}
          </a-select-option>
        </t-select>
      </a-col>
    </a-row>

    <div style="display: flex">
      <a-row :gutter="[20]">
        <a-col :span="12">

          <t-select
            :form="buyMilesForm"
            fieldKey="airline_id"
            :required="selectedMilesProgram === 'Livelo' ? false : true"
            label="Cias aéreas"
            :disabled="milesOperation === 'add-miles'"
            placeholder="Cias aéreas"
            @select="onAirlineSelected"
          >
            <a-select-option
              v-for="(airline, index) of airlines"
              :key="index"
              :value="airline.id"
            >
              {{ airline.name }}
            </a-select-option>
          </t-select>
        </a-col>
        <a-col :span="12">
          <t-select
            :form="buyMilesForm"
            fieldKey="miles_program"
            required
            label="Programa de milhas"
            :disabled="!milesPrograms.length || milesOperation === 'add-miles'"
            placeholder="Programa de milhas"
            @select="onMileProgramSelected"
          >
            <a-select-option
              v-for="(mileProgram, index) of milesPrograms"
              :key="index"
              :value="mileProgram.name"
            >
              {{ mileProgram.name }}
            </a-select-option>
          </t-select>
          <a-form-item class="travel-input-outer" style="display: none">
            <a-input v-decorator="[`miles_program_id`]"> </a-input>
          </a-form-item>
        </a-col>
        <div style="clear: both" />
        <a-col :span="12">
          <t-input
            class="normal-font"
            :form="buyMilesForm"
            fieldKey="user"
            required
            label="Usuário"
            placeholder="Usuário"
            :disabled="
              !buyMilesForm.getFieldValue('miles_program') ||
              milesOperation === 'add-miles'
            "
            :editing="
              buyMilesForm.getFieldValue('miles_program') &&
              milesOperation === 'buy-miles'
            "
          />
        </a-col>
        <a-col :span="12">
          <t-input
            :form="buyMilesForm"
            fieldKey="number"
            required
            label="Número do cartão"
            placeholder="Número do cartão"
            :disabled="
              !buyMilesForm.getFieldValue('miles_program') ||
              milesOperation === 'add-miles'
            "
            :editing="
              buyMilesForm.getFieldValue('miles_program') &&
              milesOperation === 'buy-miles'
            "
          />
        </a-col>
        <a-col :span="12">
          <t-input
            class="normal-font"
            :form="buyMilesForm"
            fieldKey="password"
            required
            label="Senha"
            placeholder="Senha"
            :disabled="
              !buyMilesForm.getFieldValue('miles_program') ||
              milesOperation === 'add-miles'
            "
            :editing="
              buyMilesForm.getFieldValue('miles_program') &&
              milesOperation === 'buy-miles'
            "
          />
        </a-col>
        <a-col :span="12">
          <t-input
            class="normal-font"
            :form="buyMilesForm"
            fieldKey="password_recovery"
            required
            label="Senha de resgate"
            placeholder="Senha de resgate"
            :disabled="
              !buyMilesForm.getFieldValue('miles_program') ||
              milesOperation === 'add-miles'
            "
            :editing="
              buyMilesForm.getFieldValue('miles_program') &&
              milesOperation === 'buy-miles'
            "
          />
        </a-col>
        <a-col :span="12">
          <t-input
            :form="buyMilesForm"
            fieldKey="quantity"
            required
            v-currency="{
              currency: {
                prefix: '',
                suffix: '',
              },
              distractionFree: false,
              valueAsInteger: false,
              precision: 0,
              autoDecimalMode: true,
              allowNegative: false,
            }"
            @change="onQuantityChange"
            label="Quantidade de milhas"
            placeholder="Quantidade de milhas"
            :disabled="!buyMilesForm.getFieldValue('miles_program')"
            :editing="buyMilesForm.getFieldValue('miles_program')"
          />
        </a-col>
        <a-col v-show="false" :span="12">
          <t-input
            :form="buyMilesForm"
            fieldKey="suggested_value"
            v-bind="{ vCurrency: true }"
            required
            readonly
            label="Valor sugerido (R$)"
            placeholder="Valor sugerido (R$)"
            :disabled="
              !buyMilesForm.getFieldValue('miles_program') ||
              milesOperation === 'add-miles'
            "
            :editing="
              buyMilesForm.getFieldValue('miles_program') &&
              milesOperation === 'buy-miles'
            "
          />
        </a-col>
        <a-col :span="12">
          <t-input
            :form="buyMilesForm"
            fieldKey="value"
            required
            v-currency="{
              currency: {
                prefix: 'R$ ',
                suffix: '',
              },
              distractionFree: false,
              valueAsInteger: false,
              precision: 2,
              autoDecimalMode: true,
              allowNegative: false,
            }"
            @change="onValueChange"
            label="Valor a pagar (R$)"
            placeholder="Valor a pagar (R$)"
            :disabled="!buyMilesForm.getFieldValue('miles_program')"
            :editing="buyMilesForm.getFieldValue('miles_program')"
            :rules="[{ validator: profitCheck }]"
          />
        </a-col>
        <a-col v-show="false" :span="12">
          <t-input
            :form="buyMilesForm"
            fieldKey="negociation"
            required
            readonly
            label="Negociação"
            placeholder="Negociação"
            :disabled="
              !buyMilesForm.getFieldValue('miles_program') ||
              milesOperation === 'add-miles'
            "
            :editing="
              buyMilesForm.getFieldValue('miles_program') &&
              milesOperation === 'buy-miles'
            "
          />
        </a-col>
        <a-col :span="12">
          <a-form-item>
            <label
              :class="
                this.buyMilesForm.getFieldValue(`expires_at`) ? 'filled' : ''
              "
            >
              Expiração das milhas
            </label>
            <a-date-picker
              class="travel-input"
              placeholder="Expiração das milhas"
              format="DD/MM/YYYY"
              :disabled-date="minDate"
              :showToday="false"
              :allowClear="false"
              :disabled="!buyMilesForm.getFieldValue('miles_program')"
              v-mask="'##/##/####'"
              v-decorator="[
                'expires_at',
                {
                  rules: [
                    {
                      required: true,
                      message: 'Obrigatório',
                    },
                  ],
                },
              ]"
            >
              <a-icon slot="prefix" type="field-svg" />
            </a-date-picker>
          </a-form-item>
        </a-col>

        <a-col :span="12">
          <a-checkbox
            :disabled="!buyMilesForm.getFieldValue('miles_program')"
            class="mt-10"
            @change="onSpecialPrinceVisibilityChange"
            v-model="isVCM"
          >
            VCM
          </a-checkbox>

          <a-form-item style="display: none">
            <t-input
              v-if="isVCM"
              :form="buyMilesForm"
              fieldKey="is_vcm"
              :initialValue="isVCM"
            />
          </a-form-item>
        </a-col>

        <div style="clear: both" />

        <a-col :span="12">
          <a-checkbox
            :disabled="!buyMilesForm.getFieldValue('miles_program')"
            class="mt-10"
            @change="onSpecialPrinceVisibilityChange"
            v-model="setSpecialPrice"
          >
            Usar preço especial?
          </a-checkbox>
        </a-col>

        <a-col v-if="setSpecialPrice" :span="12">
          <t-input
            :form="buyMilesForm"
            fieldKey="special_price"
            @change="onSpecialPriceChange"
            required
            v-currency="{
              currency: {
                prefix: 'R$ ',
                suffix: '',
              },
              distractionFree: false,
              valueAsInteger: false,
              precision: 2,
              autoDecimalMode: true,
              allowNegative: false,
            }"
            label="Preço Especial (R$)"
            placeholder="Preço Especial"
            :disabled="!buyMilesForm.getFieldValue('miles_program')"
            :editing="buyMilesForm.getFieldValue('miles_program')"
          />
        </a-col>
      </a-row>

      <a-row class="miles-preview-outer">
        <a-col :span="24">
          <div class="miles-preview mt-20 mb-10">
            <table>
              <tbody>
                <tr class="miles-preview-item">
                  <td class="miles-preview-item-label">Valor Sugerido:</td>
                  <td class="miles-preview-item-value" style="color: blue">
                    {{
                      buyMilesForm.getFieldValue("suggested_value")
                        | formatMoney
                    }}
                  </td>
                </tr>
                <tr class="miles-preview-item">
                  <td class="miles-preview-item-label">Valor a pagar:</td>
                  <td class="miles-preview-item-value" style="color: green">
                    {{ buyMilesForm.getFieldValue("value") || "R$ 0,00" }}
                  </td>
                </tr>
                <tr class="miles-preview-item">
                  <td class="miles-preview-item-label">Negociação:</td>
                  <td class="miles-preview-item-value">
                    {{
                      buyMilesForm.getFieldValue("negociation") | formatMoney
                    }}
                  </td>
                </tr>
                <tr
                  class="miles-preview-item"
                  :class="
                    buyMilesForm.getFieldValue('special_price') &&
                    convertMoneyToNumber(
                      buyMilesForm.getFieldValue('special_price')
                    ) > 0
                      ? ''
                      : ''
                  "
                >
                  <td class="miles-preview-item-label">Custo:</td>
                  <td class="miles-preview-item-value">
                    {{ finalPrice | formatMoney }}
                  </td>
                </tr>
                <tr v-if="calcCostAboutToPay().show" class="miles-preview-item">
                  <td class="miles-preview-item-label">Custo a pagar:</td>
                  <td class="miles-preview-item-value">
                    {{ calcCostAboutToPay().cost | formatMoney }}
                  </td>
                </tr>
                <tr
                  v-if="
                    buyMilesForm.getFieldValue('special_price') &&
                    convertMoneyToNumber(
                      buyMilesForm.getFieldValue('special_price')
                    ) > 0
                  "
                  class="miles-preview-item"
                >
                  <td class="miles-preview-item-label">Preço especial:</td>
                  <td class="miles-preview-item-value">
                    {{
                      buyMilesForm.getFieldValue("special_price") || "R$ 0,00"
                    }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </a-col>
      </a-row>
    </div>
  </div>
</template>

<script>
import TInput from "../../atoms/TInput.vue";
import TSelect from "../../atoms/TSelect.vue";
export default {
  name: "BuyMilesForm",
  props: ["buyMilesForm", "milesOperation"],
  components: { TInput, TSelect },
  data() {
    return {
      airlines: [],
      milesPrograms: [],
      milesPriceRange: [],
      milesCards: [],
      setSpecialPrice: false,
      isVCM: false,
      finalPrice: null,
      selectedMilesProgram: "",
    };
  },
  beforeMount() {
    this.getAirlines();
    this.$root.$on("getSupplierRes", ({ miles_cards: { data } }) => {
      this.milesCards = data;
    });
  },
  methods: {
    onSpecialPrinceVisibilityChange(e) {
      if (!e.target.checked) {
        setTimeout(() => {
          this.checkQuantityBetweenInterval(
            this.convertToInt(this.buyMilesForm.getFieldValue("quantity")),
            this.milesPriceRange
          );

          this.buyMilesForm.setFieldsValue({
            negociation: (
              parseFloat(this.buyMilesForm.getFieldValue("suggested_value")) -
              this.convertMoneyToNumber(
                this.buyMilesForm.getFieldValue("value")
              )
            ).toFixed(2),
          });
        });
      }
    },
    onSpecialPriceChange(e) {
      if (!this.convertMoneyToNumber(e.target.value)) {
        setTimeout(() => {
          this.checkQuantityBetweenInterval(
            this.convertToInt(this.buyMilesForm.getFieldValue("quantity")),
            this.milesPriceRange
          );

          this.buyMilesForm.setFieldsValue({
            negociation: (
              parseFloat(this.buyMilesForm.getFieldValue("suggested_value")) -
              this.convertMoneyToNumber(
                this.buyMilesForm.getFieldValue("value")
              )
            ).toFixed(2),
          });
        });
      }
      this.buyMilesForm.setFieldsValue({
        suggested_value: (
          ((this.convertToInt(this.buyMilesForm.getFieldValue("quantity")) ||
            0) * this.convertMoneyToNumber(e.target.value) || 0) / 1000
        ).toFixed(2),
      });

      this.buyMilesForm.setFieldsValue({
        negociation: (
          parseFloat(this.buyMilesForm.getFieldValue("suggested_value") || 0) -
          (this.convertMoneyToNumber(
            this.buyMilesForm.getFieldValue("value")
          ) || 0)
        ).toFixed(2),
      });

      this.$root.$emit("payValueChanged");
    },
    onMileCardSelected(milesCardId) {
      const selectedMilesCard = this.milesCards.find(
        (milesCard) => milesCard.id === milesCardId
      );

      this.selectedMilesProgram = selectedMilesCard.miles_program;

      this.buyMilesForm.setFieldsValue({
        airline_id: selectedMilesCard.airline.id,
        miles_program: selectedMilesCard.miles_program,
        miles_program_id: selectedMilesCard.miles_program_id,
        number: selectedMilesCard.number,
        user: selectedMilesCard.user,
        password: selectedMilesCard.password,
        password_recovery: selectedMilesCard.password_recovery,
      });

      this.$root.$emit("milesCardId", selectedMilesCard.id);

      this.milesPrograms = this.getMilesProgram(selectedMilesCard.airline.id);

      this.milesPriceRange = this.getMilesPriceRange(
        selectedMilesCard.miles_program
      );
    },
    profitCheck(rule, value, callback) {
      if (
        this.convertMoneyToNumber(value) &&
        this.convertMoneyToNumber(value) >
          this.buyMilesForm.getFieldValue("suggested_value")
      ) {
        callback("Sem lucro!");
      } else {
        callback();
      }
    },
    getAirlines() {
      // this.$http
      //     .post("/option/get", {
      //         id: 6,
      //     })
      //     .then(({ data }) => {
      //         data;
      //         // this.airlines = JSON.parse(data.value);
      //     });

      this.$http
        .get(
          `/airline/list?page=1&per_page=500&order=ascend&order-by=name&have_miles_program=yes`
        )
        .then(({ data }) => {
          data;
          this.airlines = data.data;
        })
        .catch(({ response }) => {
          response;
        });
    },
    minDate(current) {
      return current && current < new Date();
    },
    getMilesProgram(airlineId) {
      const { miles_programs } = this.airlines.find(
        (airline) => airline.id == airlineId
      );

      return miles_programs;
    },
    onAirlineSelected(value) {
      this.buyMilesForm.setFieldsValue({
        miles_program: null,
        miles_program_id: null,
      });
      this.milesPrograms = this.getMilesProgram(value);
    },
    getMilesPriceRange(mileProgramName) {
      const { miles_range } = this.milesPrograms.find(
        (milesProgram) => milesProgram.name === mileProgramName
      );
      return miles_range;
    },
    onMileProgramSelected(value) {
      const { id } = this.milesPrograms.find(
        (milesProgram) => milesProgram.name === value
      );

      this.buyMilesForm.setFieldsValue({ miles_program_id: id });
      this.milesPriceRange = this.getMilesPriceRange(value);
    },
    convertToInt(stringNumber) {
      return parseInt(stringNumber.replace(/\./g, ""));
    },
    convertMoneyToNumber(stringMoney) {
      let str = `${stringMoney}`;
      return Number(str.replace(/\./g, "").substr(3).replace(",", "."));
    },
    onQuantityChange(e) {
      this.checkQuantityBetweenInterval(
        this.convertToInt(e.target.value),
        this.milesPriceRange
      );
    },
    calcCostAboutToPay() {
      let show = false;
      let cost = 0;

      let valueToPay = this.buyMilesForm.getFieldValue("value");

      if (valueToPay != undefined && valueToPay != "") {
        let miles = this.buyMilesForm.getFieldValue("quantity");

        show = true;

        valueToPay = this.convertMoneyToNumber(valueToPay);
        miles = this.convertToInt(miles);

        cost = (valueToPay / miles) * 1000;

        this.$root.$emit("setPricePerMiles", cost);
      }

      return { show, cost };
    },
    checkQuantityBetweenInterval(quantity, intervals) {
      let finalPrice = null;
      let str = "";

      JSON.parse(intervals).forEach((interval) => {
        str += `${interval.miles_start} ${quantity} ${interval.miles_end} ${interval.price}  \n`;
      });
      str;

      JSON.parse(intervals).forEach((interval) => {
        if (
          parseFloat(quantity) >= parseFloat(interval.miles_start) &&
          parseFloat(quantity) <= parseFloat(interval.miles_end)
        ) {
          finalPrice = interval.price;
        }
      });

      if (finalPrice) {
        this.$root.$emit(
          "setPricePerMiles",
          parseFloat(finalPrice.replace(",", "."))
        );
        this.finalPrice = (
          parseFloat(finalPrice.replace(",", ".")) * 1000
        ).toFixed(2);
        this.buyMilesForm.setFieldsValue({
          price_per_miles: parseFloat(finalPrice.replace(",", ".")),
        });
        this.buyMilesForm.setFieldsValue({
          suggested_value: (
            parseFloat(quantity) * parseFloat(finalPrice.replace(",", "."))
          ).toFixed(2),
        });
      } else {
        this.buyMilesForm.setFieldsValue({
          suggested_value: null,
        });
      }
    },
    onValueChange(e) {
      if (e.target.value) {
        this.buyMilesForm.setFieldsValue({
          negociation: (
            parseFloat(this.buyMilesForm.getFieldValue("suggested_value")) -
            this.convertMoneyToNumber(e.target.value)
          ).toFixed(2),
        });

        this.$root.$emit("payValueChanged");
      } else {
        this.buyMilesForm.setFieldsValue({
          negociation: null,
        });
      }
    },
  },
};
</script>

<style lang="sass" scoped>
.buy-miles-form
    padding: 10px

.miles-preview
    width: 300px
    display: flex
    align-items: center
    padding: 5px 10px
    font-size: 12px
    justify-content: center
    border-radius: 0
    background: #FFF
    border: 1px solid #d9d9d9
    &-outer
        margin-left: 20px !important
        display: flex
        align-items: center
        background: transparent
    table
        width: 100%
    &-item
        &-label
            width: 55%
            padding: 3px
            font-weight: 600
            text-align: right
        &-value
            width: 45%
            padding: 3px
            text-align: left
</style>
