<template>
    <div>
        <h2 class="title mb-10">
            <a-row>
                <a-col :span="12">
                    <a-icon class="mr-5" type="wallet" />
                    FORMA DE PAGAMENTO
                </a-col>
            </a-row>
        </h2>

        <div style="padding: 10px">
            <a-row>
                <a-col :span="6">
                    <a-form-item>
                        <label
                            :class="
                                buyMilesForm.getFieldValue(
                                    `miles_card_installments`
                                )
                                    ? 'filled'
                                    : ''
                            "
                            >Nº de parcelas
                        </label>
                        <a-select
                            class="travel-input select"
                            placeholder="Parcelas"
                            @select="onInstallmentSelect"
                            v-decorator="[
                                `miles_card_installments`,
                                {
                                    initialValue: 1,
                                    rules: [
                                        {
                                            required: false,
                                            message: 'Obrigatório',
                                        },
                                    ],
                                },
                            ]"
                        >
                            <a-select-option
                                v-for="(item, index) of installments"
                                :key="index"
                                :value="item"
                            >
                                {{ item }}
                            </a-select-option>
                        </a-select>
                    </a-form-item>
                </a-col>
            </a-row>

            <a-row
                v-for="field in installmentsAmount"
                :key="field"
                :gutter="[20, 0]"
            >
                <a-col :span="8">
                    <t-input
                        :form="buyMilesForm"
                        :fieldKey="`payment.installments[${field}].quantity`"
                        required
                        v-currency="{
                            currency: {
                                prefix: '',
                                suffix: '',
                            },
                            distractionFree: false,
                            valueAsInteger: false,
                            precision: 0,
                            autoDecimalMode: true,
                            allowNegative: false,
                        }"
                        :editing="true"
                        :label="`Quantidade da ${field}ª parcela`"
                        :placeholder="`Quantidade da ${field}ª parcela`"
                    />
                </a-col>
                <a-col :span="8">
                    <t-input
                        :form="buyMilesForm"
                        :fieldKey="`payment.installments[${field}].value`"
                        required
                        v-currency="{
                            currency: {
                                prefix: 'R$ ',
                                suffix: '',
                            },
                            distractionFree: true,
                            valueAsInteger: false,
                            precision: 2,
                            autoDecimalMode: true,
                            allowNegative: false,
                        }"
                        :editing="true"
                        :label="`Valor da ${field}ª parcela`"
                        :placeholder="`Valor da ${field}ª parcela`"
                    />
                </a-col>
                <a-col :span="8">
                    <a-form-item class="travel-input">
                        <label
                            :class="
                                buyMilesForm.getFieldValue(
                                    `payment.installments[${field}].expiration_date`
                                )
                                    ? 'filled'
                                    : ''
                            "
                            >Vencimento ({{ field }}ª parcela)</label
                        >

<!-- :disabled-date="disabledDate" -->
                        <a-date-picker
                            class="travel-input"
                            :placeholder="`Vencimento (${field}ª parcela)`"
                            format="DD/MM/YYYY"
                            @change="onInstallmentDateSelect"
                            
                            v-mask="'##/##/####'"
                            :showToday="false"
                            v-decorator="[
                                `payment.installments[${field}].expiration_date`,
                                {
                                    rules: [
                                        {
                                            required: true,
                                            message: 'Obrigatório',
                                        },
                                    ],
                                },
                            ]"
                        >
                        </a-date-picker>
                    </a-form-item>
                </a-col>
            </a-row>
        </div>
    </div>
</template>

<script>
import TInput from "../../atoms/TInput.vue";
import moment from "moment";
export default {
    components: { TInput },
    props: ["buyMilesForm"],
    beforeMount() {
        this.onInstallmentSelect(1);

        this.$root.$on("payValueChanged", () => {
            this.onInstallmentSelect(1);
        });
    },
    data() {
        return {
            installments: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
            installmentsAmount: null,
            currentInstallmentDate: new Date(),
        };
    },
    methods: {
        disabledDate(current) {
            return (
                current &&
                current < moment(this.currentInstallmentDate).add(1, "days")
            );
        },
        convertToInt(stringNumber) {
            return parseInt(stringNumber.replace(/\./g, ""));
        },
        convertMoneyToNumber(stringMoney) {
            let str = `${stringMoney}`;
            return Number(
                str
                    .replace(/\./g, "")
                    .substr(3)
                    .replace(",", ".")
            );
        },
        moneyFormat(value) {
            let val = value.toFixed(2).replace(".", ",");
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        },
        amountFormat(value) {
            return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        },
        onInstallmentDateSelect(date) {
            console.log(date);
            this.currentInstallmentDate = date;
        },
        onInstallmentSelect(value) {
            this.installmentsAmount = value;

            setTimeout(() => {
                const installmentQuantity =
                    this.convertToInt(
                        this.buyMilesForm.getFieldValue("quantity")
                    ) / this.installmentsAmount;

                const installmentValue =
                    this.convertMoneyToNumber(
                        this.buyMilesForm.getFieldValue("value")
                    ) / this.installmentsAmount;
                for (
                    let field = 1;
                    field < this.installmentsAmount + 1;
                    field++
                ) {
                    this.buyMilesForm.setFieldsValue({
                        [`payment.installments[${field}].quantity`]: this.amountFormat(
                            parseInt(installmentQuantity)
                        ),
                    });

                    this.buyMilesForm.setFieldsValue({
                        [`payment.installments[${field}].value`]: `R$ ${this.moneyFormat(
                            installmentValue
                        )}`,
                    });
                }
            }, 100);
        },
    },
};
</script>

<style lang="scss" scoped></style>
