<template>
  <div class="content">
    <a-col :span="12">
      <a-form-item>
        <label
          :class="supplierForm.getFieldValue('first_name') ? 'filled' : ''"
        >
          Primeiro Nome
        </label>
        <a-input
          :disabled="$route.name === 'view'"
          class="travel-input"
          placeholder="Primeiro Nome"
          v-decorator="[
            'first_name',
            { rules: [{ required: true, message: 'Obrigatório' }] },
          ]"
        >
          <a-icon
            v-if="$route.name !== 'view'"
            slot="prefix"
            type="field-svg"
          />
        </a-input>
      </a-form-item>
    </a-col>
    <a-col :span="12">
      <a-form-item>
        <label :class="supplierForm.getFieldValue('last_name') ? 'filled' : ''">
          Sobrenome
        </label>
        <a-input
          :disabled="$route.name === 'view'"
          class="travel-input"
          placeholder="Último Nome"
          v-decorator="[
            'last_name',
            { rules: [{ required: true, message: 'Obrigatório' }] },
          ]"
        >
          <a-icon
            v-if="$route.name !== 'view'"
            slot="prefix"
            type="field-svg"
          />
        </a-input>
      </a-form-item>
    </a-col>

    <a-col :span="24">
      <a-form-item>
        <label
          :class="this.supplierForm.getFieldValue('email') ? 'filled' : ''"
        >
          E-mail
        </label>
        <a-input
          class="travel-input"
          placeholder="Email"
          :disabled="$route.name === 'view'"
          v-decorator="[
            'email',
            {
              rules: [
                {
                  type: 'email',
                  message: 'Escreva um e-mail válido',
                },
                {
                  required: true,
                  message: 'Obrigatório',
                },
              ],
            },
          ]"
        >
          <a-icon
            v-if="$route.name !== 'view'"
            slot="prefix"
            type="field-svg"
          />
        </a-input>
      </a-form-item>
    </a-col>

    <a-col :span="12">
      <a-form-item>
        <label :class="supplierForm.getFieldValue('birthday') ? 'filled' : ''">
          Nascimento
        </label>
        <a-date-picker
          class="travel-input"
          placeholder="Nascimento"
          :disabled="$route.name === 'view'"
          format="DD/MM/YYYY"
          v-mask="'##/##/####'"
          :showToday="false"
          :disabled-date="adultHoodDate"
          @openChange="defaultDate"
          v-decorator="[
            'birthday',
            {
              rules: [
                {
                  required: true,
                  message: 'Obrigatório',
                },
              ],
            },
          ]"
        >
        </a-date-picker>
      </a-form-item>
    </a-col>

    <a-col :span="12">
      <a-form-item>
        <label :class="supplierForm.getFieldValue('cpf') ? 'filled' : ''">
          CPF
        </label>
        <a-input
          :disabled="$route.name === 'view'"
          class="travel-input"
          placeholder="CPF"
          v-mask="'###.###.###-##'"
          v-decorator="[
            'cpf',
            {
              rules: [
                {
                  required: true,
                  min: 9,
                  message: 'Preencha todos números.',
                },
              ],
            },
          ]"
        >
          <a-icon
            v-if="$route.name !== 'view'"
            slot="prefix"
            type="field-svg"
          />
        </a-input>
      </a-form-item>
    </a-col>

    <a-col :span="10">
      <a-form-item>
        <label :class="supplierForm.getFieldValue('rg') ? 'filled' : ''">
          RG
        </label>
        <a-input
          class="travel-input"
          placeholder="RG"
          :disabled="$route.name === 'view'"
          v-mask="['##.###.###', '#.###.###', '###.###.###-##']"
          v-decorator="[
            'rg',
            {
              rules: [
                { required: true, message: 'Obrigatório' },
                { min: 9, message: 'Preencha todos números' },
              ],
            },
          ]"
        >
          <a-icon
            v-if="$route.name !== 'view'"
            slot="prefix"
            type="field-svg"
          />
        </a-input>
      </a-form-item>
    </a-col>
    <a-col :span="8">
      <a-form-item>
        <label
          :class="supplierForm.getFieldValue('rg_emissor') ? 'filled' : ''"
        >
          Órgão Emissor
        </label>
        <a-input
          class="travel-input"
          :disabled="$route.name === 'view'"
          type="text"
          placeholder="Órgão Emissor"
          v-decorator="[
            'rg_emissor',
            {
              rules: [
                {
                  required: true,
                  message: 'Obrigatório',
                },
              ],
            },
          ]"
        >
          <a-icon
            v-if="$route.name !== 'view'"
            slot="prefix"
            type="field-svg"
          />
        </a-input>
      </a-form-item>
    </a-col>

    <a-col :span="6">
      <a-form-item>
        <label :class="supplierForm.getFieldValue('rg_state') ? 'filled' : ''">
          Estado de emissão
        </label>
        <a-select
          class="travel-input"
          :disabled="$route.name === 'view'"
          show-search
          placeholder="Estado"
          v-decorator="[
            'rg_state',
            {
              rules: [
                {
                  required: true,
                  message: 'Obrigatório',
                },
              ],
            },
          ]"
        >
          <a-select-option
            v-for="(item, index) of states"
            :key="index"
            :value="item.initials"
          >
            {{ item.initials.toUpperCase() }}
          </a-select-option>
        </a-select>
      </a-form-item>
    </a-col>

    <a-col :span="12">
      <a-form-item>
        <label
          :class="supplierForm.getFieldValue('mobile_phone') ? 'filled' : ''"
        >
          Celular
        </label>
        <a-input
          class="travel-input"
          placeholder="Celular"
          :disabled="$route.name === 'view'"
          v-mask="'(##) # ####-####'"
          v-decorator="[
            'mobile_phone',
            {
              rules: [
                {
                  required: true,
                  message: 'Obrigatório',
                },
              ],
            },
          ]"
        >
          <a-icon
            v-if="$route.name !== 'view'"
            slot="prefix"
            type="field-svg"
          />
        </a-input>
      </a-form-item>
    </a-col>

    <a-col :span="12">
      <a-form-item>
        <label :class="supplierForm.getFieldValue('phone') ? 'filled' : ''">
          Telefone fixo
        </label>
        <a-input
          class="travel-input"
          placeholder="Telefone fixo"
          v-mask="'(##) ####-####'"
          :disabled="$route.name === 'view'"
          v-decorator="[
            'phone',
            {
              rules: [
                {
                  required: false,
                  message: 'Obrigatório',
                },
              ],
            },
          ]"
        >
          <a-icon
            v-if="$route.name !== 'view'"
            slot="prefix"
            type="field-svg"
          />
        </a-input>
      </a-form-item>
    </a-col>

    <AddressData
      :form="supplierForm"
      :fieldSizes="{
        cep: 10,
        location_street: 24,
        location_number: 8,
        location_complement: 16,
        location_neighborhood: 10,
        location_city: 10,
        location_state: 4,
        location_country: 10,
      }"
      :disabledCondition="$route.name === 'view'"
    />
  </div>
</template>

<script>
import { format } from "date-fns";
import subYears from "date-fns/subYears";
import AddressData from "@/components/general/AddressData";
import states from "@/json/states";

export default {
  name: "SupplierData",
  components: { AddressData },
  props: ["supplierForm"],
  data() {
    return {
      states,
    };
  },
  methods: {
    defaultDate() {
      setTimeout(() => {
        this.supplierForm.setFieldsValue({
          birthday: this.supplierForm.getFieldValue("birthday")
            ? this.supplierForm.getFieldValue("birthday")
            : format(subYears(new Date(), 18), "MM/dd/yyyy"),
        });
      }, 1);
    },
    adultHoodDate(current) {
      return current && current > subYears(new Date(), 18);
    },
  },
};
</script>